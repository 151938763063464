import React, { useEffect, lazy, Suspense, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get_category, price_range_product, query_products } from "./store/reducers/homeReducer";
import "react-multi-carousel/lib/styles.css";
import Loading from "./components/Loading";
import ProtechUser from "./utils/ProtectUser";
import Navbar from "./components/Navbar";
import Gift from "./pages/Gift";
import Footer from "./components/Footer";
import ScrollTop from "./components/ScrollTop";
import NotFound from "./components/PageNoFound";
import { initiateSocket } from "./components/notifications/notifications";
import ConfirmationShop from "./components/dashboard/shop/ConfirmationShop";
import SystemNotification from "./components/dashboard/systemNotifications/SystemNotification";

const Home = lazy(() => import("./pages/Home"));
const Shops = lazy(() => import("./pages/Shops"));
const Card = lazy(() => import("./pages/Card"));
const Details = lazy(() => import("./pages/Details"));
const Register = lazy(() => import("./pages/Register"));
const Login = lazy(() => import("./pages/Login"));
const ResetPassword = lazy(() => import("./pages/resetPassword"));
const Shipping = lazy(() => import("./pages/Shipping"));
const Payment = lazy(() => import("./pages/Payment"));
const ConfirmOrder = lazy(() => import("./pages/ConfirmOrder"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Orders = lazy(() => import("./components/dashboard/Orders"));
const CustomerWallet = lazy(() => import("./components/dashboard/CustomerWallet"));

const Wishlist = lazy(() => import("./components/dashboard/Wishlist"));
const Order = lazy(() => import("./components/dashboard/Order"));
const ChangePassword = lazy(() => import("./components/dashboard/ChangePassword"));
const ChangePasswordGeneral = lazy(() => import("./pages/changePasswordGeneral"));
const Index = lazy(() => import("./components/dashboard/Index"));
const EditProfile = lazy(() => import("./components/dashboard/EditProfile"));
const CompareProduct = lazy(() => import("./components/dashboard/CompareProduct"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Blog = lazy(() => import("./pages/Blog"));
const Policy = lazy(() => import("./pages/Policy") )
const AddProduct = lazy(() => import("./components/dashboard/seller/AddProduct"));
const AddShop=lazy(()=>import("./components/dashboard/shop/AddShop"))
const SerSeller = lazy(() => import("./components/dashboard/seller/SerSeller"));
const Products = lazy(() => import("./components/dashboard/seller/Products"));
const EditProduct = lazy(() => import("./components/dashboard/seller/EditProduct"));
const SellerDashboard = lazy(() => import("./components/dashboard/seller/SellerDashboard"));
const OrdersSeller = lazy(() => import("./components/dashboard/seller/OrdersSeller"));
const OrdersDetailsSeller = lazy(() => import("./components/dashboard/seller/OrderDetailsSeller"));
const Payments = lazy(() => import("./components/dashboard/seller/Payments"));
const OrderSummary = lazy(()=> import("./pages/OrderSummary"))
const VerificationMessage = lazy(() => import("./pages/VerificationMessage"));



function App() {
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(get_category());
  }, [dispatch]);

  //Funcionamiento global de Sidebar (para que funcione tanto en Shop como en Details)
  const { products, totalProduct, loader, categorys, priceRange, parPage } =
    useSelector((state) => state.home);

  const [pageNumber, setPageNumber] = useState(1);
  const [styles, setStyles] = useState("grid");
  const [filter, setFilter] = useState(true);
  const [category, setCategory] = useState("");
  const [state, setState] = useState({
    values: [priceRange.low, priceRange.high],
  });
  const [rating, setRatingQ] = useState("");
  const [sortPrice, setSortPrice] = useState("");
  const [sortByDate, setSortByDate] = useState("");
  const [InStock, setInStock] = useState("");
  const [tagValue, setTagValue] = useState("");
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const { userInfo } = useSelector((state) => state.auth)

  useEffect(() => {
    userInfo && initiateSocket(userInfo);
  }, [userInfo]);

  useEffect(() => {
    dispatch(price_range_product());
  }, [dispatch]);

  useEffect(() => {
    setState({
      values: [priceRange.low, priceRange.high],
    });
  }, [priceRange]);

  const queryCategoey = (e, value) => {
    if (e.target.checked) {
      setCategory(value);
    } else {
      setCategory("");
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(
        query_products({
          low: state.values[0],
          high: state.values[1],
          category,
          tagValue,
          rating,
          sortPrice,
          sortByDate,
          InStock,
          pageNumber,
          color,
          size,
        })
      );
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [
    color,
    category,
    rating,
    tagValue,
    pageNumber,
    sortPrice,
    sortByDate,
    InStock,
    size,
    dispatch,
    state.values,
  ]);

  const Reset = () => {
    setRatingQ("");
    setCategory("");
    setState({
      values: [priceRange.low, priceRange.high],
    });
    setInStock("");
    setTagValue("");
    setPageNumber(1);
    setSortPrice("");
    setColor("");
    setSortByDate("");
    setSize("");
  };

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <ScrollTop />
        
        <Navbar />
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="/verify-email" element={<VerificationMessage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/changepassword/:id" element={<ChangePasswordGeneral />} />
          <Route path="/" element={<Home />} />
          <Route
            path="/products"
            element={
              <Shops
                products={products}
                totalProduct={totalProduct}
                loader={loader}
                categorys={categorys}
                priceRange={priceRange}
                parPage={parPage}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                styles={styles}
                setStyles={setStyles}
                filter={filter}
                setFilter={setFilter}
                category={category}
                setCategory={setCategory}
                queryCategoey={queryCategoey}
                state={state}
                setState={setState}
                rating={rating}
                setRatingQ={setRatingQ}
                sortPrice={sortPrice}
                setSortPrice={setSortPrice}
                sortByDate={sortByDate}
                setSortByDate={setSortByDate}
                InStock={InStock}
                setInStock={setInStock}
                tagValue={tagValue}
                setTagValue={setTagValue}
                color={color}
                setColor={setColor}
                size={size}
                setSize={setSize}
                Reset={Reset}
              />
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/policy" element={<Policy/>} />
          <Route path="/card" element={<Card />} />
          <Route path="/shipping" element={<Shipping />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/order/confirm?" element={<ConfirmOrder />} />
          <Route path="/order/summary" element={<OrderSummary />} />
          <Route
            path="/product/details/:slug"
            element={
              <Details
                categorys={categorys}
                category={category}
                queryCategoey={queryCategoey}
                priceRange={priceRange}
                state={state}
                setState={setState}
                setSize={setSize}
                size={size}
                Reset={Reset}
              />
            }
          />
          <Route path="/gift" element={<Gift />} />
          <Route path="/cart" element={<Card />} />
          <Route path="/favorites" element={<Wishlist />} />
          <Route path="/my-compare" element={<CompareProduct />} />

          {/* Dashboard Routes */}
          <Route path="/dashboard" element={<ProtechUser />}>
            <Route path="" element={<Dashboard />}>
              <Route path="my-orders" element={<Orders />} />
              <Route path="my-wishlist" element={<Wishlist />} />
              <Route path="my-wallet" element={<CustomerWallet />} />
              <Route path="system-notifications" element={<SystemNotification />} />
              
              <Route path="" element={<Index />} />
              <Route path="edit-profile" element={<EditProfile />} />
              {/* <Route path="my-compare" element={<CompareProduct />} /> */}
              <Route path="shop" element={<AddShop />} />
              <Route path="shop/confirmation" element={<ConfirmationShop />} />
              <Route path="order/details/:orderId" element={<Order />} />
              <Route path="change-password" element={<ChangePassword />} />
              <Route path="seller" element={<SerSeller />} />
              <Route path="seller/add-product" element={<AddProduct />} />
              <Route path="seller/products" element={<Products />} />
              <Route
                path="seller/edit-product/:productId"
                element={<EditProduct />}
              />
              <Route path="seller/control" element={<SellerDashboard />} />
              <Route path="seller/orders" element={<OrdersSeller />} />
              <Route
                path="seller/orders/details/:orderId"
                element={<OrdersDetailsSeller />}
              />
              <Route path="seller/payments" element={<Payments />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
