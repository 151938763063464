import React from "react";
import { useState } from "react";
import españa from '../assets/españa.png'
import eeuu from '../assets/eeuu.webp'
import venezuela from '../assets/venezuela.png'

const Marquee = () => {
  return (
  
      
<div className="flex justify-center text-2xl font-bold uppercase text-white bg-gray-600 p-1">
  <div className="flex flex-row justify-center">
    <h1>Ingresa desde</h1>
    <div className="flex gap-4 ml-4">
      <button>
        <img
          src={españa}
          alt="Bandera Española"
          className="h-4 w-6 object-cover"
        />
      </button>
      <button>
        <img
          src={eeuu}
          alt="Bandera Estadounidense"
          className="h-4 w-6 object-cover"
        />
      </button>
      <button>
        <img
          src={venezuela}
          alt="Bandera Venezolana"
          className="h-4 w-6 object-cover"
        />
      </button>
    </div>
  </div>
</div>

   
  );
};

export default Marquee;
