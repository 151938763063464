import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaTwitter, FaGooglePlusG, FaInstagram } from "react-icons/fa";
import api from "../api/api"; // Asegúrate de que esta ruta sea correcta

const Footer = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await api.get("/category-get");
        setCategories(data.categorys);
      } catch (error) {
        console.error("Error al obtener las categorías:", error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <footer className="bg-zinc-900 text-white">
    <div className="container mx-auto px-4 lg:px-16 py-8">
      {/* Contenedor principal */}
      <div className="flex flex-col md:flex-row justify-between items-start text-center md:text-left">
        {/* MENU */}
        <div className="flex flex-col mb-6 md:mr-8">
          <h3 className="text-redFooter mb-4 text-h3lg">MENU</h3>
          <ul className="space-y-2">
            <li>
              <Link to={"/products"} className="text-gray-400 hover:text-white">
                Categoria
              </Link>
            </li>
            <li>
              <Link to={"/dashboard"} className="text-gray-400 hover:text-white">
                Mi perfil
              </Link>
            </li>
            <li>
              <Link to={"/gift"} className="text-gray-400 hover:text-white">Gift</Link>
            </li>
            <li>
              <Link to={"/blog"} className="text-gray-400 hover:text-white">Blog</Link>
            </li>
            <li>
              <Link to={"/card"} className="text-gray-400 hover:text-white">Carrito</Link>
            </li>
          </ul>
        </div>
  
        {/* CATEGORÍAS */}
        <div className="flex flex-col mb-6 md:mr-8">
          <h3 className="text-redFooter mb-4 text-h3lg">CATEGORÍAS</h3>
          <ul className="space-y-2">
            {categories.map((category) => (
              <li key={category._id}>
                <Link
                  to={`/products?category=${category.slug}`}
                  className="text-gray-400 hover:text-white"
                >
                  {category.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
  
        {/* MI PERFIL */}
        <div className="flex flex-col mb-6 md:mr-8">
          <h3 className="text-redFooter mb-4 text-h3lg">MI PERFIL</h3>
          <ul className="space-y-2">
            <li>
              <Link to={"/register"} className="text-gray-400 hover:text-white">
                Registrarse
              </Link>
            </li>
            <li>
              <Link to={"/login"} className="text-gray-400 hover:text-white">Ingresar</Link>
            </li>
          </ul>
        </div>
  
        {/* CONTACTO */}
        <div className="flex flex-col mb-6">
          <h3 className="text-redFooter mb-4 text-h3lg">CONTACTO</h3>
          <div className="flex justify-center md:justify-start space-x-4">
            <Link className="bg-redFooter rounded-full p-2 hover:bg-secondaryRed transition-colors">
              <FaFacebookF className="text-white text-xl" />
            </Link>
            <Link className="bg-redFooter rounded-full p-2 hover:bg-secondaryRed transition-colors">
              <FaTwitter className="text-white text-xl" />
            </Link>
            <Link className="bg-redFooter rounded-full p-2 hover:bg-secondaryRed transition-colors">
              <FaGooglePlusG className="text-white text-xl" />
            </Link>
            <Link className="bg-redFooter rounded-full p-2 hover:bg-secondaryRed transition-colors">
              <FaInstagram className="text-white text-xl" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  
    {/* Footer Bottom */}
    <div className="bg-neutral-300 py-2">
      <div className="container mx-auto px-4 lg:px-16 flex flex-col md:flex-row justify-center md:justify-between items-center text-center md:text-left">
        <p className="text-sm text-gray-600 mb-2 md:mb-0">Derechos de autor</p>
        <Link to={"/policy"} className="text-gray-400 hover:text-white">
          <p className="text-sm text-gray-600">Términos y condiciones, privacidad y políticas</p>
        </Link>
      </div>
    </div>
  </footer>
  );
};

export default Footer;

